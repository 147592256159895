export const education = [
  {
    name: "Edirne Yildirim Beyazit Anatolian High School",
    degree: "",
    startDate: "2015",
    endDate: "2019",
    completed: true,
  },
  {
    name: "Trakya University",
    degree: "BSc Computer Engineering",
    startDate: "2019",
    endDate: "2023",
    expected: "2023",
    completed: false,
  },
];
